<template>
  <div>
    <div>
      <div class="mainTitle">物资配置</div>
      <div class="content">
        包含物资基础信息、使用单位信息、使用岗位信息、物资配置周期、核算单位、配置数量授权可根据人、部门、岗位、等多维度设置。可在项目中选已在物资配置中配置好的标准进行
        项目配置。
      </div>
      <img src="@/assets/productcore/wuzi1.png" alt="" />
    </div>
        <div>
      <div class="mainTitle">物资申购</div>
      <div class="content">
        满足物资在各种情况下需提交的申购申请，按月生成申购计划根据人员权限开放不同按钮，使申购简单、便捷、节省时间，在申购中系统可自动识别当前库存物资数量，尽可能减少不
必要的申购。
      </div>
      <img src="@/assets/productcore/wuzi2.png" alt="" />
    </div>
            <div>
      <div class="mainTitle">物资采购</div>
      <div class="content">
       由计划申购、专项申购合并的申购单，在采购中分配各个供应商在选中一条采购单中，对采
购单中物资进行供应商订单明细采购模块中供应商管理可进行供应商属性、评价等。
      </div>
      <img src="@/assets/productcore/wuzi3.png" alt="" />
    </div>
                <div>
      <div class="mainTitle">物资管理</div>
      <div class="content">
      支持对计划申购，一键入库功能，快捷方便，对物资可进行灵活操作<br/>
所有信息，可追溯、可查询支持物资各项业务操作，如︰调拨、借用、领用、报废等。
      </div>
      <img src="@/assets/productcore/wuzi4.png" alt="" />
    </div>
                    <div>
      <div class="mainTitle">物资统计</div>
      <div class="content">
     针对于仓库进行每月结转统计可查看物资出入库统计，并对仓库物资数量清楚掌握。
      </div>
      <img src="@/assets/productcore/wuzi5.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "material-manage",
};
</script>

<style scoped>
.mainTitle {
  font-size: 30px;
  color: #333;
  margin: 56px 0 40px;
  position: relative;
}
.mainTitle::after {
  content: "";
  width: 100px;
  height: 4px;
  background-color: #d7d7d7;
  position: absolute;
  left: 50%;
  bottom: -2px;
  transform: translateX(-50%);
}
.content {
  color: #333;
  font-size: 21px;
  line-height: 30px;
  margin-bottom: 32px;
  letter-spacing: 0.2px;
}
img {
  width: 86%;
}
</style>